import { useState } from 'react';
import './App.css';

export default function App() {
  const [count, setCount] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Perform login logic here with username and password
    console.log('Login submitted:', username, password);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">
          Hello David!
        </h1>

        <div className="mb-8 text-center">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full text-xl"
            onClick={handleIncrement}
          >
            Count: {count}
          </button>
        </div>

        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">Login Form</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="username" className="block mb-2 font-bold">
                Username:
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block mb-2 font-bold">
                Password:
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full text-xl"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
